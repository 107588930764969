import React from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const GradeComponent = ({ grade, id }: { grade: any; id: string }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/class/${id}/${grade?.name}`);
  };
  return (
    <div
      onClick={handleClick}
      className="flex cursor-pointer items-center justify-between border-2 px-2 border-[#C2C2F7] rounded-[60px] py-2 "
    >
      <p className="w-[25px] mx-4 h-[25px] border-2 border-[#C2C2F7] rounded-full"></p>
      <p>{grade?.name}</p>

      <div className="h-[30px] w-[2px] bg-[#C2C2F7] mx-3"></div>
      <Avatar.Group
        size="large"
        max={{
          count: 8,
          style: {
            color: "#f56a00",
            backgroundColor: "#fde3cf",
            cursor: "pointer",
          },
          popover: { trigger: "click" },
        }}
      >
        {Array.from({ length: 4 }).map((_, index) => (
          <Avatar
            key={index}
            style={{ backgroundColor: "#87d068" }}
            icon={<UserOutlined />}
          />
        ))}
      </Avatar.Group>

      <div className="h-[30px] w-[2px] bg-[#C2C2F7] mx-3"></div>

      <p>{grade?.type}</p>

      <div className="h-[30px] w-[2px] bg-[#C2C2F7] mx-3"></div>

      <p className="mr-4">
        {" "}
        {`Grade ${grade.ageGroup[0]} - Grade ${
          grade.ageGroup[grade.ageGroup.length - 1]
        }`}{" "}
      </p>
    </div>
  );
};

export default GradeComponent;

import React, { useState, ReactNode, useEffect, useReducer } from "react";
import BaseService from "../helpers/baseServices";
import { getCookie, setCookie } from "../helpers/utils";

interface State {
  schoolID: string;
  schoolName: string;
  school: any;
}

type Action =
  | { type: "SET_SCHOOL_ID"; payload: string }
  | { type: "SET_SCHOOL_NAME"; payload: string }
  | { type: "SET_SCHOOL"; payload: string };

const initialState: State = {
  schoolID: getCookie("schoolID") || "",
  schoolName: getCookie("schoolname") || "",
  school: getCookie("schoolData") || "",
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_SCHOOL_ID":
      setCookie("schoolID", action.payload);
      return { ...state, schoolID: action.payload };
    case "SET_SCHOOL_NAME":
      setCookie("schoolname", action.payload);
      return { ...state, schoolName: action.payload };
    case "SET_SCHOOL":
      return { ...state, school: action.payload };
    default:
      return state;
  }
};

export const GeneralContext = React.createContext<{
  ChosenSchools: any;
  schoolID: string;
  schoolPopulation: string;
  schoolName: string;
  schoolnameCookie: string;
  schoolIDCookie: string;
  AllSingleSchool: any[];
  isLoadingDetails: boolean;
  Chosen: (schoolID: string) => void;
  ChosenName: (schoolname: string) => void;
}>({
  ChosenSchools: {},
  schoolPopulation: "",
  AllSingleSchool: [],
  schoolnameCookie: "",
  schoolIDCookie: "",
  schoolID: "",
  schoolName: "",
  Chosen: () => {},
  ChosenName: () => {},
  isLoadingDetails: false,
});

export const GeneralContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { schoolID, schoolName, school } = state;
  const [isLoadingDetails, setLoadingDetails] = useState<boolean>(false);
  const [ChosenSchools, setChosenSchools] = useState<any>({ schoolsData: [] });
  const [AllSingleSchool, setAllSingleSchool] = useState<any[]>([]);
  const [schoolPopulation, setSchoolPopulation] = useState("");
  const [schoolIDCookie, setSchoolIDCookie] = useState<string>(
    getCookie("schoolID") || ""
  );
  const [schoolnameCookie, setSchoolnameCookie] = useState<string>(
    getCookie("schoolname") || ""
  );

  useEffect(() => {
    const updatedSchoolIDCookie = getCookie("schoolID");
    const updatedSchoolnameCookie = getCookie("schoolname");

    if (updatedSchoolIDCookie && updatedSchoolIDCookie !== schoolID) {
      dispatch({ type: "SET_SCHOOL_ID", payload: updatedSchoolIDCookie });
    }

    if (updatedSchoolnameCookie && updatedSchoolnameCookie !== schoolName) {
      dispatch({ type: "SET_SCHOOL_NAME", payload: updatedSchoolnameCookie });
    }
  }, [schoolID, schoolName]);

  useEffect(() => {
    try {
      const parsedArraySchool = JSON.parse(school);
      const parsedSchool = parsedArraySchool.filter(
        (singleSchool: any) => singleSchool._id === schoolID
      );
      setSchoolPopulation(parsedSchool[0]?.schoolInformation?.leanerPopulation);
    } catch (error: any) {
      console.log("Error from getting parsed School", error.message);
    }
  }, [school, schoolID]);

  const getInstrucDetails = async () => {
    setLoadingDetails(true);
    try {
      const response = await BaseService.get_api(
        `/robocentre/roboinstructor/onboarding/instructor-me`
      );
      const { payload } = await response.data;
      // console.log("context Payload: ", response);
      setChosenSchools(payload || { schoolsData: [] });
    } catch (error: any) {
      console.error("Error fetching instructor details: ", error.message);
    } finally {
      setLoadingDetails(false);
    }
  };

  useEffect(() => {
    getInstrucDetails();
  }, []);

  const Chosen = (schoolID: string) => {
    dispatch({ type: "SET_SCHOOL_ID", payload: schoolID });
  };

  const ChosenName = (schoolname: string) => {
    dispatch({ type: "SET_SCHOOL_NAME", payload: schoolname });
  };

  return (
    <GeneralContext.Provider
      value={{
        ChosenSchools,
        schoolPopulation,
        schoolID,
        schoolName,
        schoolnameCookie,
        schoolIDCookie,
        AllSingleSchool,
        isLoadingDetails,
        Chosen,
        ChosenName,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

import { useContext, useState, useEffect, useCallback } from "react";
import { GeneralContext } from "../../../context/generalContext";
import { Modal, Select, Spin } from "antd";
import { DownOutlined } from "@ant-design/icons";
import SchoolComponent from "../../../pages/SchoolSelection/SchoolComponent";
import NavBarSchoolComponent from "./NavBarSchoolComponent";
import { setCookie } from "../../../helpers/utils";

const NavSearchBar = () => {
  const { ChosenSchools, schoolnameCookie, Chosen, ChosenName, schoolName } =
    useContext(GeneralContext);

  const [schoolname, setSchoolName] = useState<string>(schoolName);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [SelectedValue, setSelectedValue] = useState<string>("");

  const showModal = () => setIsModalOpen(true);

  const handleCancel = () => setIsModalOpen(false);

  const handleSelectSchool = useCallback((selected: string, name: string) => {
    setSelectedValue(selected);
    setSchoolName(name);
    ChosenName(name);
    Chosen(selected);
    setCookie("schoolID", selected, 365);
    setCookie("schoolname", name, 365);
  }, []);

  return (
    <>
      <div
        onClick={showModal}
        className="border-[1px] rounded-lg  border-blue-200 w-[400px] h-[40px] px-4 py-2 flex items-center justify-between cursor-pointer"
      >
        <span>{schoolname || "Select a school"}</span>
        <DownOutlined className="text-[#6666ea]" />
      </div>

      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={"70%"}
        styles={{
          mask: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
          body: {
            minHeight: "40vh",
          },
        }}
      >
        <h1 className="p-2 text-[28px] font-bold text-center my-8 text-[#6666EA] leading-tight tracking-wide bg-gradient-to-r from-[#6666EA] to-[#a8a8ff] bg-clip-text text-transparent">
          Switch Between Schools
        </h1>
        <div className="flex flex-col gap-4">
          {ChosenSchools?.schoolsData?.length > 0 ? (
            ChosenSchools.schoolsData.map((school: any) => (
              <NavBarSchoolComponent
                onClickHandler={handleSelectSchool}
                id={school._id}
                schoolName={school?.schoolInformation.schoolName}
                key={school._id}
                selected={SelectedValue === school._id}
              />
            ))
          ) : (
            <div className="w-full flex justify-center items-end">
              {" "}
              <Spin />{" "}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default NavSearchBar;

import React, { useCallback, useEffect, useState, useContext } from "react";
import roboCenter from "../../images/roboCenter.svg";
import SchoolComponent from "./SchoolComponent";
import { GeneralContext } from "../../context/generalContext";
import { setCookie } from "../../helpers/utils";
import { Spin } from "antd";

const SchoolSelection = () => {
  const [SelectedValue, setSelectedValue] = useState<string>("");
  const [schoolname, setSchoolName] = useState<string>("");
  const { ChosenSchools, Chosen, ChosenName } = useContext(GeneralContext);

  useEffect(() => {
    if (SelectedValue) {
      Chosen(SelectedValue);
      ChosenName(schoolname);
      setCookie("schoolID", SelectedValue, 365);
      setCookie("schoolname", schoolname, 365);
    }
  }, [SelectedValue, Chosen, ChosenName]);

  const handleSelectSchool = useCallback((selected: string, name: string) => {
    Chosen(selected);
    ChosenName(name);
    setSelectedValue(selected);
    setSchoolName(name);
  }, []);

  return (
    <section>
      <div className="w-full flex flex-col mt-[75px] justify-center items-center">
        <div
          className="w-[262px] h-[65px]"
          style={{
            backgroundImage: `url(${roboCenter})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "10px",
          }}
        ></div>
        <p className="font-[500] text-[18px] mt-[13px] text-[#0F0F37]">
          Select a school to manage
        </p>
      </div>

      <div className="flex flex-wrap p-10 gap-3">
        {ChosenSchools?.schoolsData && ChosenSchools.schoolsData.length > 0 ? (
          ChosenSchools.schoolsData.map((school: any) => (
            <SchoolComponent
              key={school._id}
              id={school._id}
              schoolName={school?.schoolInformation.schoolName}
              population={school?.schoolInformation?.leanerPopulation}
              onClickHandler={handleSelectSchool}
              selected={SelectedValue === school._id}
              school={ChosenSchools.schoolsData}
            />
          ))
        ) : (
          <div className="w-full flex justify-center items-end">
            {" "}
            <Spin />{" "}
          </div>
        )}
      </div>
    </section>
  );
};

export default SchoolSelection;

import Navbar from "../../components/navbar/Navbar";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import { useContext, useState } from "react";
import { GeneralContext } from "../../context/generalContext";
import overviewIcon from "../../images/overviewicon.svg";
import attendanceIcon from "../../images/attendanceIcon.svg";
import taskunit from "../../images/taskunit.svg";
import Attendance from "./Tabs/Attendance/Attendance";
import { Button } from "antd";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

const tabNames = [
  {
    name: "Attendance",
    icon: attendanceIcon,
    color: ["#9494F5", "#6666EA"],
  },
  {
    name: "Overview",
    icon: overviewIcon,
    color: ["#B46EFC", "#903EE4"],
  },
  {
    name: "Tasks & Units",
    icon: taskunit,
    color: ["#FCCF3B", "#FFC400"],
  },
];

const ClassDetails = () => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const { gradeName } = useParams();

  const Tabs = [<Attendance />];
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      <ProSideBar myClassActive="active" myClassIcon="dashIconActive" />
      <div className="flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        <div className="flex mb-14 mt-4 items-center px-3 gap-4">
          <Button
            className="customText w-[90px] h-[40px] rounded-[20px] text-white bg-gradient-to-r from-[#9494F5] to-[#6666EA] hover:bg-white  transition-all duration-300"
            icon={<FaArrowLeft />}
            onClick={() => navigate("/class")}
          >
            Back
          </Button>
          <p className="text-[32px] text-[#201E5A] font-[700] text-text_color">
            {gradeName || "Grade"}
          </p>
        </div>

        <div className="flex items-center px-4 gap-4 ">
          {tabNames.map((tab, i) => (
            <div
              key={i}
              className={`flex items-center gap-2 w-[200px] py-3  drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] hover:drop-shadow-none rounded-[20px] cursor-pointer transition-all duration-300 ${
                activeTab === i ? "text-white" : "text-black"
              }`}
              style={{
                background:
                  activeTab === i
                    ? `linear-gradient(to right, ${tab.color[0]}, ${tab.color[1]})`
                    : "#FFFFFF",
                padding: "10px 20px",
              }}
              onClick={() => handleTabClick(i)}
            >
              <img
                src={tab.icon}
                alt={`${tab.name} icon`}
                className="w-10 h-10"
              />
              <span className="text-[16px] font-[300]">{tab.name}</span>
            </div>
          ))}
        </div>

        <div>
          <div>{Tabs[activeTab]}</div>
        </div>
      </div>
    </div>
  );
};

export default ClassDetails;

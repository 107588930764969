import { Button, Select } from "antd";
import React, { useContext, useState } from "react";
import { GeneralContext } from "../../../../context/generalContext";
import { useParams } from "react-router-dom";
import GradeComponent from "../../../my_class/GradeComponent";
import StudentsData from "./StudentsData";
import { FaArrowLeft } from "react-icons/fa";
import TimeTableModal from "./TimeTableModal";

const Attendance = () => {
  const { ChosenSchools } = useContext(GeneralContext);
  const [open, setOpen] = useState(false);

  const { gradeId, gradeName } = useParams();
  const grades = ChosenSchools?.gradesData || [];

  const showModal = () => {
    setOpen(true);
  };

  return (
    <div className="flex text-text_deep gap-2  justify-between px-4 drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] w-full  rounded-[20px] my-4 ">
      <section className="w-[80%]">
        <div className="text-text_deep  min-h-[70vh] rounded-[20px]  bg-white">
          <h1 className="text-[32px] p-4 font-[600] text-[#201E5A]">
            Attendance
          </h1>
          <section>
            <div className="flex p-4 gap-2  justify-between">
              <Select
                id="category"
                className=" w-[250px] h-[40px] "
                options={[
                  { value: "M", label: "Male" },
                  { value: "F", label: "Female" },
                  { value: "O", label: "Other" },
                ]}
                placeholder="Select Gender"
                allowClear
              />
              <Select
                id="category"
                className=" w-[250px] h-[40px] "
                options={[
                  { value: "M", label: "Male" },
                  { value: "F", label: "Female" },
                  { value: "O", label: "Other" },
                ]}
                placeholder="Select Gender"
                allowClear
              />
              <Select
                id="category"
                className=" w-[250px] h-[40px] "
                options={[
                  { value: "M", label: "Male" },
                  { value: "F", label: "Female" },
                  { value: "O", label: "Other" },
                ]}
                placeholder="Select Gender"
                allowClear
              />
            </div>

            {/* Table Wrapper */}
            <div className=" w-full px-4 flex flex-col bg-white  rounded-md">
              {/* Table Header */}
              <div className="flex justify-between items-center border-b border-gray-300   ">
                <div className="text-center w-full">Student</div>
                <div className="text-center w-full">Status</div>
                <div className="text-center w-full">Grade Levels</div>
              </div>

              {/* Table Body */}
              <div className="flex flex-col w-full divide-y divide-gray-300">
                {grades.length > 0 ? (
                  grades.map((grade: any) => (
                    <div
                      key={grade._id}
                      className="flex justify-between py-3 px-4 hover:bg-defaultBlue_5"
                    >
                      <StudentsData id={grade._id} grade={grade} />
                    </div>
                  ))
                ) : (
                  <p className="text-center py-4">No grades available</p>
                )}
              </div>
            </div>
          </section>
        </div>
      </section>
      <section className="flex-auto flex justify-end -mt-48  drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)]   rounded-[20px] ">
        <TimeTableModal
          showModal={showModal}
          gradeName={gradeName}
          open={open}
          setOpen={setOpen}
        />
      </section>
    </div>
  );
};

export default Attendance;

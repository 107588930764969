import { Button, Modal, notification, TimePicker } from "antd";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import BaseService from "../../../helpers/baseServices";

const { RangePicker: TimeRangePicker } = TimePicker;

const EditTimeTable = ({
  showModal,
  open,
  EditModalData,
}: {
  showModal: any;
  open: any;
  EditModalData: any;
}) => {
  const { _id, courseId, acadermictermId, meetings } = EditModalData;

  const [LoadingDetails, setLoadingDetails] = useState(false);
  const [MondayTime, setMondayTime] = useState<any>([null, null]);
  const [TuesdayTime, setTuesdayTime] = useState<any>([null, null]);
  const [WednesdayTime, setWednesdayTime] = useState<any>([null, null]);
  const [ThursdayTime, setThursdayTime] = useState<any>([null, null]);
  const [FridayTime, setFridayTime] = useState<any>([null, null]);

  const getNextDayOfWeek = (dayOfWeek: number) => {
    const today = dayjs();
    const dayOffset = (dayOfWeek + 7 - today.day()) % 7;
    return today.add(dayOffset, "day");
  };

  const onChangeTime = (setTime: any) => (time: any) => {
    if (time && time.length === 2) {
      setTime(time);
    } else {
      setTime([null, null]);
    }
  };

  const handleSubmit = async () => {
    const timetable = [
      { day: "Monday", time: MondayTime, dayOfWeek: 1 },
      { day: "Tuesday", time: TuesdayTime, dayOfWeek: 2 },
      { day: "Wednesday", time: WednesdayTime, dayOfWeek: 3 },
      { day: "Thursday", time: ThursdayTime, dayOfWeek: 4 },
      { day: "Friday", time: FridayTime, dayOfWeek: 5 },
    ];

    const validTimetable = timetable.filter(
      ({ time }) => time && time[0] && time[1]
    );

    if (validTimetable.length === 0) {
      notification.error({
        message:
          "At least one valid day with both start and end times is required.",
      });
      return;
    }

    setLoadingDetails(true);

    try {
      const finalData = {
        _id,
        courseId,
        acadermictermId,
        meetings: validTimetable.map(({ time, dayOfWeek }) => {
          const dayDate = getNextDayOfWeek(dayOfWeek).format("YYYY-MM-DD");
          const start = dayjs(
            `${dayDate}T${time[0].format("HH:mm:ss")}`
          ).toISOString();
          const end = dayjs(
            `${dayDate}T${time[1].format("HH:mm:ss")}`
          ).toISOString();

          return {
            start,
            end,
          };
        }),
      };

      await BaseService.put_api(
        `/robocentre/roboinstructor/setup/calender`,
        finalData
      );

      notification.success({
        message: "Calendar information updated successfully",
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || "Error occurred",
      });
    } finally {
      setLoadingDetails(false);
    }
  };

  useEffect(() => {
    setMondayTime([null, null]);
    setTuesdayTime([null, null]);
    setWednesdayTime([null, null]);
    setThursdayTime([null, null]);
    setFridayTime([null, null]);

    if (meetings && meetings.length > 0) {
      meetings.forEach((meeting: any) => {
        const startTime = dayjs(meeting.start);
        const endTime = dayjs(meeting.end);
        const dayOfWeek = startTime.day();

        switch (dayOfWeek) {
          case 1:
            setMondayTime([startTime, endTime]);
            break;
          case 2:
            setTuesdayTime([startTime, endTime]);
            break;
          case 3:
            setWednesdayTime([startTime, endTime]);
            break;
          case 4:
            setThursdayTime([startTime, endTime]);
            break;
          case 5:
            setFridayTime([startTime, endTime]);
            break;
          default:
            break;
        }
      });
    }
  }, [meetings, EditModalData]);

  return (
    <>
      <Modal width={"80%"} open={open} onCancel={showModal} footer={null}>
        <div className="rounded-2xl px-4">
          <p className="text-2xl mt-1 font-body text-text_primary mb-3 font-bold">
            Edit Timetable
          </p>

          <div className="flex flex-wrap gap-3">
            <div>
              <h2>Monday</h2>
              <TimeRangePicker
                format="HH:mm"
                value={MondayTime}
                onChange={onChangeTime(setMondayTime)}
              />
            </div>
            <div>
              <h2>Tuesday</h2>
              <TimeRangePicker
                format="HH:mm"
                value={TuesdayTime}
                onChange={onChangeTime(setTuesdayTime)}
              />
            </div>
            <div>
              <h2>Wednesday</h2>
              <TimeRangePicker
                format="HH:mm"
                value={WednesdayTime}
                onChange={onChangeTime(setWednesdayTime)}
              />
            </div>
            <div>
              <h2>Thursday</h2>
              <TimeRangePicker
                format="HH:mm"
                value={ThursdayTime}
                onChange={onChangeTime(setThursdayTime)}
              />
            </div>
            <div>
              <h2>Friday</h2>
              <TimeRangePicker
                format="HH:mm"
                value={FridayTime}
                onChange={onChangeTime(setFridayTime)}
              />
            </div>
          </div>

          <div className="flex justify-end mt-8">
            <Button
              size="large"
              onClick={handleSubmit}
              loading={LoadingDetails}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditTimeTable;

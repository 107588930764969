import { Avatar } from "antd";
import React, { useContext, useEffect } from "react";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { GeneralContext } from "../../context/generalContext";
import { setCookie } from "../../helpers/utils";

interface SchoolComponentProps {
  onClickHandler: (id: string, name: string) => void;
  id: string;
  schoolName: string;
  selected: boolean;
  population: string;
  school: any;
}

const SchoolComponent: React.FC<SchoolComponentProps> = ({
  onClickHandler,
  id,
  population,
  schoolName,
  school,
  selected,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onClickHandler(id, schoolName);
    navigate("/dashboard");
  };

  useEffect(() => {
    setCookie("schoolData", JSON.stringify(school), 365);
  }, [school]);
  return (
    <section
      onClick={handleClick}
      className={`relative cursor-pointer mx-2 transition-transform duration-300 ease-in-out hover:scale-105 flex-auto p-4 drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[10px] flex justify-between items-center ${
        selected ? "bg-[#5BDEC844] border-2 border-[#C2C2F7]" : "bg-white"
      }`}
    >
      <div className="flex items-center gap-4">
        <div className="w-[70px] h-[70px] text-[24px] rounded-full bg-[#5BDEC899] flex items-center justify-center transition-all duration-300 ease-in-out">
          {`${schoolName.slice(0, 2)}`}
        </div>
        <p className="text-[21px]">{schoolName}</p>
      </div>
      <div className="flex items-center">
        <div className="flex flex-col items-end">
          <p className="text-sm mb-2">{`${population}` || 0} students</p>
          <Avatar.Group
            size="large"
            max={{
              count: 8,
              style: {
                color: "#f56a00",
                backgroundColor: "#fde3cf",
                cursor: "pointer",
              },
              popover: { trigger: "click" },
            }}
          >
            {Array.from({ length: 4 }).map((_, index) => (
              <Avatar
                key={index}
                style={{ backgroundColor: "#87d068" }}
                icon={<UserOutlined />}
              />
            ))}
          </Avatar.Group>
        </div>
      </div>
    </section>
  );
};

export default SchoolComponent;

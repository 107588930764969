import React from "react";

const NavBarSchoolComponent = ({
  selected,
  onClickHandler,
  id,
  schoolName,
}: {
  selected: any;
  onClickHandler: any;
  id: any;
  schoolName: any;
}) => {
  const handleClick = () => {
    onClickHandler(id, schoolName);
  };

  return (
    <section
      onClick={handleClick}
      className={`relative  w-full   p-2 cursor-pointer mx-2 transition-transform duration-300 ease-in-out  flex-auto rounded-[10px] flex justify-between items-center ${
        selected
          ? "bg-[#5BDEC844] border-2 border-[#C2C2F7]"
          : "hover:bg-blue-300"
      } `}
    >
      <div className="flex items-center gap-4  w-full">
        <div className="w-[20px] h-[20px] p-3  rounded-full bg-[#5BDEC899] flex items-center justify-center transition-all duration-300 ease-in-out">
          {`${schoolName.slice(0, 2)}`}
        </div>
        <p className="text-[21px]  text-black">{schoolName}</p>
      </div>
    </section>
  );
};

export default NavBarSchoolComponent;

import { ProSidebarProvider } from "react-pro-sidebar";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "../src/css/App.css";
import "./css/GlobalStyles.css";
import Dashboard from "./pages/dashboard/Dashboard";
import Courses from "./pages/courses/Courses";
import CourseGroup from "./pages/year_group/CourseGroup";
import Classroom from "./pages/year_group/Classroom/Classroom";
import MyClass from "./pages/my_class/MyClass";
import Settings from "./pages/settings_page/Settings";
import StudentDetails from "./pages/student_details/StudentDetails";
import Reporting from "./pages/reporting/Reporting";
import PreviewReport from "./pages/reporting/generate_report/PreviewReport";
import Preferences from "./pages/reporting/generate_report/report_preferences/Preferences";
import AddQuiz from "./pages/addQuiz/AddQuiz";
import PreviewQuiz from "./pages/addQuiz/PreviewQuiz";
import { GeneralContextProvider } from "./context/generalContext";
import TokenProtectedRoutes from "./router/TokenProtectedRoutes";
import OnBoardingProtectedRoutes from "./router/OnBoardingProtectedRoutes";
import OnBoard from "./pages/OnBoard/OnBoard";
import Account from "./pages/Account/Account";
import Calender from "./pages/Calender/Calender";
import SchoolSelection from "./pages/SchoolSelection/SchoolSelection";
import ClassDetails from "./pages/ClassDetails/ClassDetails";

function App() {
  return (
    <ProSidebarProvider>
      <GeneralContextProvider>
        <Router>
          <Routes>
            <Route path="*" element={<h4>Page not found!!</h4>} />

            <Route element={<TokenProtectedRoutes />}>
              {/* <Route path="/" element={<OnBoardingProtectedRoutes />}> */}
              <Route path="/" element={<Dashboard />}>
                {/* If not onboarded, show onboarding page */}
                <Route index element={<OnBoard />} />
              </Route>

              <Route path="/choose_school" element={<SchoolSelection />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/calendar" element={<Calender />} />
              <Route path="/account" element={<Account />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/year_group" element={<CourseGroup />} />
              <Route path="/year_overview" element={<Classroom />} />
              <Route path="/class" element={<MyClass />} />
              <Route
                path="/class/:gradeId/:gradeName"
                element={<ClassDetails />}
              />
              <Route path="/settings" element={<Settings />} />
              <Route path="/student_details" element={<StudentDetails />} />
              <Route path="/reporting" element={<Reporting />} />
              <Route path="/preview_report" element={<PreviewReport />} />
              <Route path="/report_preference" element={<Preferences />} />
              <Route path="/add_quiz" element={<AddQuiz />} />
              <Route path="/preview_quiz" element={<PreviewQuiz />} />
            </Route>
          </Routes>
        </Router>
      </GeneralContextProvider>
    </ProSidebarProvider>
  );
}

export default App;

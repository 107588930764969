import React, { useEffect, useState, useCallback, useContext } from "react";
import { Select } from "antd";
import { GeneralContext } from "../../../context/generalContext";

interface theProps {
  onChange: (selected: string) => void;
  gradeLevel?: string;
}

interface GradeLevelProps {
  label: string;
  value: string;
}

const GradeLevel = ({ onChange }: theProps) => {
  const { ChosenSchools } = useContext(GeneralContext);

  const grades = ChosenSchools?.gradesData || [];

  const [allGradeLevel, setAllGradeLevel] = useState<GradeLevelProps[]>([]);
  const [selectedGradeLevel, setSelectedGradeLevel] = useState<string>("");

  const fetchGradeLevel = () => {
    const arrangedResponse: GradeLevelProps[] = grades.map((item: any) => ({
      label: item.name,
      value: item._id,
    }));
    setAllGradeLevel(arrangedResponse);

    if (arrangedResponse.length > 0) {
      setSelectedGradeLevel(arrangedResponse[0].value);
      onChange(arrangedResponse[0].value);
    }
  };

  useEffect(() => {
    fetchGradeLevel();
  }, [grades]);

  // useEffect(() => {
  //   if (gradeLevel) {
  //     setSelectedGradeLevel(gradeLevel);
  //   }
  // }, [gradeLevel]);

  const handleChange = (value: string) => {
    setSelectedGradeLevel(value);
    onChange(value);
  };

  return (
    <div>
      <Select
        id="category"
        value={selectedGradeLevel || undefined}
        className="absolute top-[20px] left-52 h-[40px] w-[200px]"
        options={allGradeLevel}
        onChange={handleChange}
        placeholder="Select Grade Level"
        allowClear
      />
    </div>
  );
};

export default GradeLevel;

import Navbar from "../../components/navbar/Navbar";
import ProSideBar from "../../components/sidebar/pro-sidebar";

import React, { useEffect, useReducer, useState } from "react";
import { Button, Input, UploadProps, Upload, notification, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import { LoadingOutlined } from "@ant-design/icons";
import BaseService from "../../helpers/baseServices";
import { RiDeleteBin7Line } from "react-icons/ri";
import basicElectronics_img from "../../images/bigRobo.png";
import { useNavigate } from "react-router-dom";
import SelectSchools from "../OnBoard/FormComponents/SelectSchools";
import SelectGradeLevel from "../OnBoard/FormComponents/SelectGradeLevel";
import SelectGender from "../OnBoard/FormComponents/SelectGender";
import { getCookie, setCookie } from "../../helpers/utils";
import { COOKIES_USER_DATA } from "../../helpers/constants";

interface State {
  professionalName: string;
  professionalSummary: string;
  schoolId: string[];
  gradeLevelIds: string[];
  gender: string;
  nationalID: string;
  frontImage: string;
  backImage: string;
  DOB: string;
}

type Action =
  | { type: "SET_PROFESSIONAL_NAME"; payload: string }
  | { type: "SET_PROFESSIONAL_SUMMARY"; payload: string }
  | { type: "SET_SCHOOL_IDS"; payload: string[] }
  | { type: "SET_GRADE_LEVELS_IDS"; payload: string[] }
  | { type: "SET_GENDER"; payload: string }
  | { type: "SET_NATIONAL_ID"; payload: string }
  | { type: "SET_DOB"; payload: string }
  | { type: "SET_DELETED_BACK_IMAGE"; payload: string }
  | { type: "SET_DELETED_FRONT_IMAGE"; payload: string }
  | { type: "SET_FRONT_IMAGE"; payload: string }
  | { type: "SET_BACK_IMAGE"; payload: string }
  | { type: "GET_PREVIOUS_DATA"; payload: any };

const initialState: State = {
  professionalName: "",
  professionalSummary: "",
  schoolId: [],
  gradeLevelIds: [],
  gender: "",
  nationalID: "",
  frontImage: "",
  backImage: "",
  DOB: "",
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_PROFESSIONAL_NAME":
      return { ...state, professionalName: action.payload };
    case "SET_PROFESSIONAL_SUMMARY":
      return { ...state, professionalSummary: action.payload };
    case "SET_SCHOOL_IDS":
      return { ...state, schoolId: action.payload };
    case "SET_GRADE_LEVELS_IDS":
      return { ...state, gradeLevelIds: action.payload };
    case "SET_GENDER":
      return { ...state, gender: action.payload };
    case "SET_NATIONAL_ID":
      return { ...state, nationalID: action.payload };
    case "SET_DOB":
      return { ...state, DOB: action.payload };
    case "SET_DELETED_FRONT_IMAGE":
      return { ...state, frontImage: action.payload };
    case "SET_DELETED_BACK_IMAGE":
      return { ...state, backImage: action.payload };
    case "SET_FRONT_IMAGE":
      return { ...state, frontImage: action.payload };
    case "SET_BACK_IMAGE":
      return { ...state, backImage: action.payload };
    case "GET_PREVIOUS_DATA":
      return {
        ...state,
        professionalName: action.payload.professionalName || "",
        professionalSummary: action.payload.profesionalSummary || "",
        schoolId: action.payload.schoolId || [],
        gradeLevelIds: action.payload.gradeLevelIds || [],
        gender: action.payload.gender || "",
        nationalID: action.payload.nationalId || "",
        frontImage: action.payload.nationalIdImageFront || "",
        backImage: action.payload.nationalIdImageBack || "",
        DOB: action.payload.dob || "",
      };
    default:
      return state;
  }
};

const Account = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDetails, setLoadingDetails] = useState<boolean>(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [ImageLoading, setImageLoading] = useState(false);
  const [ImageBACKLoading, setBACKImageLoading] = useState(false);
  const [getAllInstrucions, setAllInstructions] = useState<any>();

  const navigate = useNavigate();

  const handleInputChange = (type: Action["type"], value: any) => {
    dispatch({ type, payload: value });
  };

  const getInstrucDetails = async () => {
    setLoadingDetails(true);
    try {
      const response = await BaseService.get_api(
        `/robocentre/roboinstructor/onboarding/instructor-me`
      );
      const { payload } = await response.data;
      setAllInstructions(payload);
      dispatch({ type: "GET_PREVIOUS_DATA", payload: payload });
      // console.log("getInstrucDetails : ", payload);
    } catch (error: any) {
      console.log("Error fetching getInstrucDetails payload: ", error.message);
    } finally {
      setLoadingDetails(false);
    }
  };

  useEffect(() => {
    getInstrucDetails();
  }, []);

  const myprops: UploadProps = {
    action: "",
    async onChange(info) {
      const file = info.file.originFileObj;
      if (file) {
        setImageLoading(true);
        try {
          const fileFormat = file.type.split("/")[1];
          const imgRes: any = await BaseService.file_upload(
            file.name,
            file,
            fileFormat
          );

          console.log("imgRes : ", imgRes);

          dispatch({
            type: "SET_FRONT_IMAGE",
            payload: imgRes?.data?.url,
          });
          if (info.file.status === "error")
            notification.success({
              message: `Image uploaded successfully`,
            });
        } catch (error: any) {
          console.log("error from file upload : ", error.message);

          notification.error({
            message: `Image failed to uplaod`,
          });
        } finally {
          setImageLoading(false);
        }
      }
    },
  };
  const handleDeleteImg = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch({
      type: "SET_DELETED_FRONT_IMAGE",
      payload: "",
    });
  };

  const mypropsBack: UploadProps = {
    action: "",
    async onChange(info) {
      const file = info.file.originFileObj;
      if (file) {
        setBACKImageLoading(true);
        try {
          const fileFormat = file.type.split("/")[1];
          const imgRes: any = await BaseService.file_upload(
            file.name,
            file,
            fileFormat
          );

          console.log("imgRes : ", imgRes);

          dispatch({
            type: "SET_BACK_IMAGE",
            payload: imgRes?.data?.url,
          });
          if (info.file.status === "error")
            notification.success({
              message: `Image uploaded successfully`,
            });
        } catch (error: any) {
          notification.error({
            message: `Image failed to uplaod`,
          });
          console.log("error from file upload : ", error.message);
        } finally {
          setBACKImageLoading(false);
        }
      }
    },
  };
  const handleBackDeleteImg = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch({
      type: "SET_DELETED_BACK_IMAGE",
      payload: "",
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userData = getCookie(COOKIES_USER_DATA);
      const { userid } = JSON.parse(userData);
      console.log("userid : ", userid);

      const payload = {
        _id: getAllInstrucions._id,
        professionalName: state.professionalName,
        gradeLevelIds: state.gradeLevelIds,
        schoolId: state.schoolId,
        profesionalSummary: state.professionalSummary,
        gender: state.gender,
        nationalId: state.nationalID,
        nationalIdImageFront: state.frontImage,
        nationalIdImageBack: state.backImage,
      };
      await BaseService.put_api(
        `/robocentre/roboinstructor/onboarding/instructor`,
        payload
      );
      setCookie("onBoardingData", JSON.stringify(payload));
      notification.success({
        message: `Learner information updated successfully`,
      });
      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
    } catch (error: any) {
      notification.error({
        message: ` ${error?.response?.data?.error}` || "Error Occured",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("state  : ", state);
  }, [state]);

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar dashboardActive="active" dashIcon="dashIconActive" />

      {/* Content */}
      <div className=" flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <div className="container-fluid pt-2 pb-[58px] px-[16px] h-full overflow-y-auto">
          <Spin
            spinning={isLoadingDetails}
            indicator={
              <LoadingOutlined
                style={{ fontSize: 64, color: "#6666EA" }}
                spin
              />
            }
          >
            <h1 className="text-[34px] my-4 text-[#0F0F37] font-bold">
              Update Professional Information
            </h1>
            <div className="h-full  ">
              <div className="bg-white rounded-[20px] p-6 ">
                <section className="flex justify-between gap-10">
                  <div className="flex flex-col gap-6 w-full">
                    <div>
                      <label className="block text-[#0F0F37] mb-1">
                        Professional Name
                      </label>
                      <Input
                        value={state.professionalName}
                        onChange={(e) =>
                          handleInputChange(
                            "SET_PROFESSIONAL_NAME",
                            e.target.value
                          )
                        }
                        placeholder="Enter professional name"
                        className="h-[48px] border rounded-[10px]"
                      />
                    </div>
                    <div>
                      <label className="block text-[#0F0F37] mb-1">
                        Professional Summary
                      </label>
                      <TextArea
                        value={state.professionalSummary}
                        onChange={(e) =>
                          handleInputChange(
                            "SET_PROFESSIONAL_SUMMARY",
                            e.target.value
                          )
                        }
                        placeholder="Provide essential Professional details"
                        rows={4}
                        className="border rounded-[10px]"
                      />
                    </div>

                    <SelectSchools
                      schoolId={state.schoolId}
                      onChange={(selected) =>
                        handleInputChange("SET_SCHOOL_IDS", selected)
                      }
                    />
                    <SelectGradeLevel
                      gradeLevelIds={state.gradeLevelIds}
                      onChange={(selected) =>
                        handleInputChange("SET_GRADE_LEVELS_IDS", selected)
                      }
                    />
                    <SelectGender
                      gender={state.gender}
                      onChange={(selected) =>
                        handleInputChange("SET_GENDER", selected)
                      }
                    />
                    <div>
                      <label className="block text-[#0F0F37] mb-1">
                        National Identification
                      </label>
                      <Input
                        value={state.nationalID}
                        onChange={(e) =>
                          handleInputChange("SET_NATIONAL_ID", e.target.value)
                        }
                        placeholder="Enter national Id"
                        className="h-[48px] border rounded-[10px]"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="sticky top-6 flex flex-col gap-6">
                      <div className="bg-white  mt-5  drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[20px]   p-[24px]">
                        <div className="flex items-center justify-center flex-col">
                          {!state.frontImage ? (
                            <img
                              src={basicElectronics_img}
                              alt="avatar"
                              className="rounded-full w-[133px] h-[133px]"
                            />
                          ) : (
                            <div
                              className="rounded-[10px]"
                              style={{
                                width: "133px",
                                height: "133px",
                                backgroundImage: `url(${state.frontImage})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                              }}
                            />
                          )}
                          <p className="text-[#6E6C8E] mt-2 text-center">
                            National Id Image Front{" "}
                          </p>
                        </div>
                        <div className="flex items-center justify-center mt-4">
                          <Button
                            className="border-none rounded-[20px] text-[#DE3B40]"
                            icon={<RiDeleteBin7Line />}
                            onClick={handleDeleteImg}
                          >
                            Remove
                          </Button>

                          <Upload.Dragger
                            showUploadList={false}
                            {...myprops}
                            className="w-full relative"
                            style={{
                              borderColor: "#E0E0FB",
                              border: "none",
                              background: "white",
                              color: "#5353C2",
                            }}
                          >
                            {ImageLoading ? (
                              <p className="bg-green-300 px-2 py-1 w-full text-center rounded-lg">
                                Uploading...
                              </p>
                            ) : (
                              <div className="flex gap-2 items-center">
                                <p className="text-[#5353C2] rounded-[20px] border border-[#E0E0FB] py-[4px] px-[16px]">
                                  Upload
                                </p>
                              </div>
                            )}
                          </Upload.Dragger>
                        </div>
                      </div>

                      {/* <ImageUpload image={image} alt="National Id Image Front" /> */}
                      <div className="bg-white    drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[20px]   p-[24px]">
                        <div className="flex items-center justify-center flex-col">
                          {!state.backImage ? (
                            <img
                              src={basicElectronics_img}
                              alt="avatar"
                              className="rounded-full w-[133px] h-[133px]"
                            />
                          ) : (
                            <div
                              className="rounded-[10px]"
                              style={{
                                width: "133px",
                                height: "133px",
                                backgroundImage: `url(${state.backImage})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                              }}
                            />
                          )}
                          <p className="text-[#6E6C8E] mt-2 text-center">
                            National Id Image Back{" "}
                          </p>
                        </div>
                        <div className="flex items-center justify-center mt-4">
                          <Button
                            className="border-none rounded-[20px] text-[#DE3B40]"
                            icon={<RiDeleteBin7Line />}
                            onClick={handleBackDeleteImg}
                          >
                            Remove
                          </Button>

                          <Upload.Dragger
                            showUploadList={false}
                            {...mypropsBack}
                            className="w-full relative"
                            style={{
                              borderColor: "#E0E0FB",
                              border: "none",
                              background: "white",
                              color: "#5353C2",
                            }}
                          >
                            {ImageBACKLoading ? (
                              <p className="bg-green-300 px-2 py-1 w-full text-center rounded-lg">
                                Uploading...
                              </p>
                            ) : (
                              <div className="flex gap-2 items-center">
                                <p className="text-[#5353C2] rounded-[20px] border border-[#E0E0FB] py-[4px] px-[16px]">
                                  Upload
                                </p>
                              </div>
                            )}
                          </Upload.Dragger>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="flex justify-end mt-6">
                  <Button
                    onClick={handleSubmit}
                    className="rounded-[40px] py-2 px-10 text-[#6666EA] hover:text-[#6666EA]"
                    disabled={isLoading}
                  >
                    {isLoading ? "Submitting..." : "Update"}
                  </Button>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default Account;

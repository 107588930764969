import Navbar from "../../components/navbar/Navbar";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import { Select, Spin } from "antd";
import GradeComponent from "./GradeComponent";
import { useContext } from "react";
import { GeneralContext } from "../../context/generalContext";

const MyClass = () => {
  const { ChosenSchools } = useContext(GeneralContext);

  const grades = ChosenSchools?.gradesData || [];

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar myClassActive="active" myClassIcon="dashIconActive" />

      {/* Content */}
      <div className="flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        <h1 className="text-[32px] mb-10 font-[600] text-defaultBlue px-3">
          Classes
        </h1>
        <div className="text-text_deep drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] w-[80%] mx-3 rounded-[20px] my-4 bg-white">
          <section>
            <div className="flex p-4 mb-2 justify-between">
              <Select
                id="category"
                className=" w-[250px] h-[40px] "
                options={[
                  { value: "M", label: "Male" },
                  { value: "F", label: "Female" },
                  { value: "O", label: "Other" },
                ]}
                placeholder="Select Gender"
                allowClear
              />
              <Select
                id="category"
                className=" w-[250px] h-[40px] "
                options={[
                  { value: "M", label: "Male" },
                  { value: "F", label: "Female" },
                  { value: "O", label: "Other" },
                ]}
                placeholder="Select Gender"
                allowClear
              />
            </div>

            {/* Table Wrapper */}
            <div className="min-h-[60vh] flex flex-col bg-white  rounded-md">
              {/* Table Header */}
              <div className="flex justify-between items-center border-b border-defaultBlue_5  py-2 ">
                <div className="text-center w-full">Grade Levels</div>
              </div>

              {/* Table Body */}
              <div className="flex flex-col w-full divide-y divide-gray-300">
                {grades.length > 0 ? (
                  grades.map((grade: any) => (
                    <div
                      key={grade._id}
                      className="flex justify-between py-3 px-4 hover:bg-defaultBlue_5"
                    >
                      <GradeComponent id={grade._id} grade={grade} />
                    </div>
                  ))
                ) : (
                  <div className="w-full flex justify-center items-end">
                    {" "}
                    <Spin />{" "}
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default MyClass;

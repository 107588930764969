import Navbar from "../../components/navbar/Navbar";
import ProSideBar from "../../components/sidebar/pro-sidebar";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useContext, useEffect, useState, useRef } from "react";
import { EventSourceInput } from "@fullcalendar/core/index.js";
import GradeLevel from "./FormElements/GradeLevel";
import BaseService from "../../helpers/baseServices";
import { GeneralContext } from "../../context/generalContext";
import EditTimeTable from "./Modals/EditTimeTable";
import rrulePlugin from "@fullcalendar/rrule";
import { Spin } from "antd";

interface ExtendedEvent extends Event {
  extendedProps: {
    description?: string;
    location?: string;
    priority?: string;
  };
}

interface Event {
  title: string;
  start: Date | string;
  end?: Date | string;
  allDay: boolean;
  id: number;
  backgroundColor?: string;
  borderColor?: string;
}

const Calender = () => {
  const [allEvents, setAllEvents] = useState<Event[]>([]);
  const calendarRef = useRef<any>(null);

  const [isAcadamciDataLoading, setAcadamciDataLoading] =
    useState<boolean>(false);
  const [CalenderGradeLevel, setCalenderGradeLevel] = useState("");
  const [ActualData, setActualData] = useState<{
    term?: string;
    programType?: string;
    meetings?: { start: string; end: string }[];
  }>({});
  const { schoolID } = useContext(GeneralContext);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<ExtendedEvent | null>(
    null
  );

  const [EditModalData, setEditModalData] = useState<any>();

  const getSingleAcadamicData = async () => {
    if (!schoolID || !CalenderGradeLevel) {
      console.log("Error: Missing school or grade level information.");
      return;
    }

    setAcadamciDataLoading(true);
    try {
      const response = await BaseService.get_api(
        `/robocentre/event-faqs/${schoolID}/${CalenderGradeLevel}`
      );
      const { payload } = await response.data;
      const allvalidData = {
        term: payload?.academicTermData?.name,
        meetings: payload?.meetings,
        programType: payload?.schoolData?.schoolInformation?.programType,
      };
      const editData = {
        _id: payload._id,
        courseId: payload?.courseId,
        acadermictermId: payload.acadermictermId,
        schoolId: payload.schoolId,
        gradeLevel: payload.gradeLevel,
        meetings: payload.meetings,
      };
      setEditModalData(editData);
      setActualData(allvalidData);
      console.log("get a single value from update : ", payload);
    } catch (error: any) {
      console.log("Error fetching payload: ", error.message);
    } finally {
      setAcadamciDataLoading(false);
    }
  };

  useEffect(() => {
    if (!schoolID || !CalenderGradeLevel) return;

    setAllEvents([]);
    if (calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.removeAllEvents();
    }

    getSingleAcadamicData();
  }, [schoolID, CalenderGradeLevel]);

  useEffect(() => {
    if (ActualData?.meetings && ActualData.meetings.length > 0) {
      addMeetingsToCalendar(ActualData.meetings);
    } else {
      setAllEvents([]);
    }
  }, [ActualData]);

  function addMeetingsToCalendar(meetings: { start: string; end: string }[]) {
    const eventsToAdd: Event[] = meetings.map((meeting, index) => {
      const startDate = new Date(meeting.start);
      const endDate = new Date(meeting.end);
      const duration = (endDate.getTime() - startDate.getTime()) / 1000 / 60; // Duration in minutes

      return {
        title: `Meeting (${ActualData.programType || "N/A"} - ${
          ActualData.term || "N/A"
        })`,
        start: startDate,
        allDay: false,
        id: index + 1,
        backgroundColor: "#A3A3F2",
        rrule: {
          freq: "weekly",
          interval: 1,
          dtstart: startDate.toISOString(), // Start of the recurring event
          until: new Date(
            new Date().setMonth(new Date().getMonth() + 6)
          ).toISOString(), // End recurrence in 6 months
        },
        duration: `${duration}m`, // Set duration of each meeting
      };
    });

    setAllEvents(eventsToAdd);

    if (calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.removeAllEvents();
      eventsToAdd.forEach((event) => {
        calendarApi.addEvent(event);
      });
    }
  }

  const handleEventClick = (clickInfo: any) => {
    const clickedEvent = clickInfo.event as ExtendedEvent;
    setSelectedEvent(clickedEvent);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedEvent(null);
  };

  return (
    <div className="flex flex-row overflow-hidden h-[100vh] w-full">
      {/* Sidebar */}
      <ProSideBar dashboardActive="active" dashIcon="dashIconActive" />

      {/* Content */}
      <div className="flex flex-col flex-1 w-full overflow-x-hidden h-full">
        <Navbar />

        {/* main content */}
        <h1 className="px-[16px] text-[28px] font-bold text-defaultBlue ">
          Calendar - {ActualData.programType || "Unknown Program"} (
          {ActualData.term || "Unknown Term"})
        </h1>

        <main className="mt-6">
          <Spin spinning={isAcadamciDataLoading}>
            <div className="p-[16px] relative">
              <GradeLevel
                onChange={(selected) => {
                  setCalenderGradeLevel(selected);
                }}
              />
              <FullCalendar
                ref={calendarRef}
                plugins={[
                  dayGridPlugin,
                  interactionPlugin,
                  timeGridPlugin,
                  rrulePlugin,
                ]}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "",
                }}
                initialView="timeGridWeek"
                initialDate={
                  allEvents.length > 0
                    ? new Date(allEvents[0].start)
                    : new Date()
                }
                events={allEvents as EventSourceInput}
                eventClick={handleEventClick}
                eventContent={(arg) => {
                  const startDate = arg.event.start?.toLocaleDateString();
                  const endDate = arg.event.end?.toLocaleDateString();

                  return (
                    <div className="h-full w-full flex flex-col justify-center text-center">
                      <b>{arg.event.title}</b>
                      <p>
                        {startDate} {endDate && ` - ${endDate}`}
                      </p>
                    </div>
                  );
                }}
                height="auto"
                nowIndicator={true}
                editable={true}
                selectable={true}
                selectMirror={true}
                slotMinTime="06:00:00"
                slotMaxTime="18:00:00"
              />
            </div>

            {EditModalData && (
              <EditTimeTable
                EditModalData={EditModalData}
                showModal={handleCloseModal}
                open={isModalVisible}
              />
            )}
          </Spin>
        </main>
      </div>
    </div>
  );
};

export default Calender;

import { Button, Modal, notification, TimePicker, TimePickerProps } from "antd";
import React, { useContext, useReducer, useState } from "react";
import { CiSettings } from "react-icons/ci";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { GeneralContext } from "../../../../context/generalContext";
import AttendCourseID from "./AttendCourseID";
import AttendAcademic from "./AttendAcademic";
import BaseService from "../../../../helpers/baseServices";
import { useParams } from "react-router-dom";
const { RangePicker: TimeRangePicker } = TimePicker;

dayjs.extend(customParseFormat);

type Action =
  | { type: "SET_COURSE_ID"; payload: string }
  | { type: "SET_ACADEMIC_ID"; payload: string }
  | { type: "SET_MEETING_ID"; payload: any }
  | { type: "SET_FORM_VALIDITY"; payload: boolean };

const initialState = {
  courseId: "",
  acadermictermId: "",
  meetings: [],
  isFormValid: false,
};

const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case "SET_COURSE_ID":
      return { ...state, courseId: action.payload };
    case "SET_ACADEMIC_ID":
      return { ...state, acadermictermId: action.payload };
    case "SET_MEETING_ID":
      return { ...state, meetings: action.payload };

    case "SET_FORM_VALIDITY":
      return { ...state, isFormValid: action.payload };
    default:
      return state;
  }
};

const TimeTableModal = ({
  showModal,
  open,
  gradeName,
  setOpen,
}: {
  showModal: any;
  gradeName: any;
  open: any;
  setOpen: any;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { schoolID } = useContext(GeneralContext);
  const [MondayTime, setMondayTime] = useState<any>([null, null]);
  const [TuesdayTime, setTuesdayTime] = useState<any>([null, null]);
  const [WednesdayTime, setWednesdayTime] = useState<any>([null, null]);
  const [ThursdayTime, setThursdayTime] = useState<any>([null, null]);
  const [FridayTime, setFridayTime] = useState<any>([null, null]);
  const [LoadingDetails, setLoadingDetails] = useState(false);

  const { gradeId } = useParams();

  const handleCancel = () => {
    setOpen(false);
  };

  const handleInputCourseChange = (type: Action["type"], value: any) => {
    dispatch({ type, payload: value });
  };

  const handleInputAcademicChange = (type: Action["type"], value: any) => {
    dispatch({ type, payload: value });
  };

  const getNextDayOfWeek = (dayOfWeek: number) => {
    const today = dayjs();
    const dayOffset = (dayOfWeek + 7 - today.day()) % 7;
    return today.add(dayOffset, "day");
  };

  const onChangeTime = (setTime: any) => (time: any) => {
    if (time && time.length === 2) {
      setTime(time);
    } else {
      setTime([null, null]);
    }
  };
  const handleSubmit = async () => {
    const timetable = [
      { day: "Monday", time: MondayTime, dayOfWeek: 1 },
      { day: "Tuesday", time: TuesdayTime, dayOfWeek: 2 },
      { day: "Wednesday", time: WednesdayTime, dayOfWeek: 3 },
      { day: "Thursday", time: ThursdayTime, dayOfWeek: 4 },
      { day: "Friday", time: FridayTime, dayOfWeek: 5 },
    ];

    const validTimetable = timetable.filter(
      ({ time }) => time && time[0] && time[1]
    );

    if (validTimetable.length === 0) {
      notification.error({
        message:
          "At least one valid day with both start and end times is required.",
      });
      return;
    }

    setLoadingDetails(true);

    try {
      const finalData = {
        schoolId: schoolID,
        gradeLevel: gradeId,
        courseId: state.courseId,
        acadermictermId: state.acadermictermId,
        meetings: validTimetable.map(({ time, dayOfWeek }) => {
          const dayDate = getNextDayOfWeek(dayOfWeek).format("YYYY-MM-DD");
          const start = dayjs(
            `${dayDate}T${time[0].format("HH:mm:ss")}`
          ).toISOString();
          const end = dayjs(
            `${dayDate}T${time[1].format("HH:mm:ss")}`
          ).toISOString();

          return {
            start,
            end,
          };
        }),
      };

      console.log("finalData : ", finalData);
      await BaseService.post_api(
        `/robocentre/roboinstructor/setup/calender`,
        finalData
      );

      notification.success({
        message: "Calendar information created successfully",
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || "Error occurred",
      });
    } finally {
      setLoadingDetails(false);
    }
  };
  return (
    <>
      <Button
        type="default"
        icon={<CiSettings size={"2em"} style={{ marginRight: 8 }} />}
        className="flex items-center  h-[50px] text-[#9494F5] border-1 border-[#9494F5] rounded-[10px]"
        onClick={showModal}
      >
        <p className="text-[18px]">Timetable Settings</p>
      </Button>
      <Modal
        width={"80%"}
        open={open}
        onCancel={handleCancel}
        styles={{
          mask: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
          body: {
            minHeight: "40vh",
          },
        }}
        footer={null}
      >
        <div className=" rounded-2xl px-4">
          <p className="text-2xl mt-1 font-body text-text_primary mb-3 font-bold">
            {`Create Timetable for ${gradeName}`}
          </p>
          <div className="flex flex-col gap-6 w-full">
            <AttendCourseID
              onChange={(selected) =>
                handleInputCourseChange("SET_COURSE_ID", selected)
              }
            />
            <AttendAcademic
              onChange={(selected) =>
                handleInputAcademicChange("SET_ACADEMIC_ID", selected)
              }
              schoolId={schoolID}
            />
          </div>

          <div className="flex flex-wrap mt-8 gap-3">
            <div>
              <h2>Monday</h2>
              <TimeRangePicker
                format="HH:mm"
                value={MondayTime}
                onChange={onChangeTime(setMondayTime)}
              />
            </div>
            <div>
              <h2>Tuesday</h2>
              <TimeRangePicker
                format="HH:mm"
                value={TuesdayTime}
                onChange={onChangeTime(setTuesdayTime)}
              />
            </div>
            <div>
              <h2>Wednesday</h2>
              <TimeRangePicker
                format="HH:mm"
                value={WednesdayTime}
                onChange={onChangeTime(setWednesdayTime)}
              />
            </div>
            <div>
              <h2>Thursday</h2>
              <TimeRangePicker
                format="HH:mm"
                value={ThursdayTime}
                onChange={onChangeTime(setThursdayTime)}
              />
            </div>
            <div>
              <h2>Friday</h2>
              <TimeRangePicker
                format="HH:mm"
                value={FridayTime}
                onChange={onChangeTime(setFridayTime)}
              />
            </div>
          </div>
          <div className="flex justify-end mt-8">
            <Button
              size="large"
              onClick={handleSubmit}
              loading={LoadingDetails}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TimeTableModal;

import { Navigate, Outlet } from "react-router-dom";
import { ACCOUNTS_URL, COOKIES_TOKEN_NAME } from "../helpers/constants";
import { getCookie } from "../helpers/utils";

const TokenProtectedRoutes = () => {
  const token = getCookie(COOKIES_TOKEN_NAME);

  if (!token) {
    const redirectUrl = `${ACCOUNTS_URL}/?appref=${encodeURIComponent(
      window.location.href
    )}`;
    return <Navigate to={redirectUrl} replace />;
  }

  return <Outlet />;
};

export default TokenProtectedRoutes;

import { useState } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import {
  Bars3Icon,
  CalendarDaysIcon,
  Cog6ToothIcon,
  FolderIcon,
  HomeIcon,
  PresentationChartLineIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import logoImg from "../../images/logo.png";
import robocenterImg from "../../images/roboCenter.svg";

interface sideBarProps {
  dashboardActive?: string;
  dashIcon?: string;
  calendarActive?: string;
  calenIcon?: string;
  coursesActive?: string;
  coursesIcon?: string;
  courseGroupIcon?: string;
  courseGroupActive?: string;
  myClassActive?: string;
  myClassIcon?: string;
  academicIcon?: string;
  academicActive?: string;
  reportActive?: string;
  reportIcon?: string;
  settingsActive?: string;
  settingsIcon?: string;
}

const ProSideBar = ({
  dashboardActive,
  dashIcon,
  calendarActive,
  academicActive,
  academicIcon,
  calenIcon,
  coursesActive,
  coursesIcon,
  courseGroupActive,
  courseGroupIcon,
  myClassActive,
  myClassIcon,
  reportActive,
  reportIcon,
  settingsActive,
  settingsIcon,
}: sideBarProps) => {
  const { collapseSidebar } = useProSidebar();
  const [menuCollapse, setmenuCollapse] = useState(false);

  const menuIconClick = () => {
    setmenuCollapse(!menuCollapse);
    collapseSidebar();
  };

  return (
    <Sidebar
      collapsed={menuCollapse}
      className="h-[100vh] bg-white"
      id="mySidebar"
    >
      <Menu className="relative">
        {/* logo */}
        <div className="flex gap-[24px] ml-6 mt-[15px]">
          <Link
            className={menuCollapse ? "" : ""}
            to="#"
            onClick={menuIconClick}
          >
            <Bars3Icon className="w-[30px] h-[30px] text-defaultBlue" />
          </Link>
          <div>
            {menuCollapse ? (
              ""
            ) : (
              <Image src={robocenterImg} width={"80%"} alt="logo" fluid />
            )}
          </div>
        </div>

        <br />

        {/* menus */}
        <MenuItem
          icon={
            <HomeIcon
              className={`w-6 h-6 text-secondary_bg text-[#6E6C8E] ${dashIcon}`}
            />
          }
          className={dashboardActive}
          component={<Link to="/dashboard" />}
        >
          <p className="font-normal text-[#6E6C8E] text-[16px] mt-1">
            Dashboard
          </p>
        </MenuItem>
        <MenuItem
          icon={
            <UserGroupIcon
              className={`w-6 h-6 text-secondary_bg text-[#6E6C8E] ${myClassIcon}`}
            />
          }
          className={myClassActive}
          component={<Link to="/class" />}
        >
          <p className="font-normal text-[#6E6C8E] text-[16px] mt-1">Class</p>
        </MenuItem>
        <MenuItem
          icon={
            <CalendarDaysIcon
              className={`w-6 h-6 text-secondary_bg text-[#6E6C8E] ${calenIcon}`}
            />
          }
          className={calendarActive}
          component={<Link to="/calendar" />}
        >
          <p className="font-normal text-[#6E6C8E] text-[16px] mt-1">
            Calendar
          </p>
        </MenuItem>

        <MenuItem
          icon={
            <FolderIcon
              className={`w-6 h-6 text-secondary_bg text-[#6E6C8E] ${coursesIcon}`}
            />
          }
          className={coursesActive}
          component={<Link to="/courses" />}
        >
          <p className="font-normal text-[#6E6C8E] text-[16px] mt-1">Courses</p>
        </MenuItem>

        <MenuItem
          icon={
            <UserGroupIcon
              className={`w-6 h-6 text-secondary_bg text-[#6E6C8E] ${courseGroupIcon}`}
            />
          }
          className={courseGroupActive}
          component={<Link to="/year_group" />}
        >
          <p className="font-normal text-[#6E6C8E] text-[16px] mt-1">
            Year Group
          </p>
        </MenuItem>

        <hr className="text-defaultBlue_40 my-[20px] mx-[27px]" />

        <MenuItem
          icon={
            <CalendarDaysIcon
              className={`w-6 h-6 text-secondary_bg text-[#6E6C8E] ${academicIcon}`}
            />
          }
          className={academicActive}
          component={<Link to="/academic_year" />}
        >
          <p className="font-normal text-[#6E6C8E] text-[16px] mt-1">
            Academic Year
          </p>
        </MenuItem>

        <MenuItem
          icon={
            <PresentationChartLineIcon
              className={`w-6 h-6 text-secondary_bg text-[#6E6C8E] ${reportIcon}`}
            />
          }
          className={reportActive}
          component={<Link to="/reporting" />}
        >
          <p className="font-normal text-[#6E6C8E] text-[16px] mt-1">
            Reporting
          </p>
        </MenuItem>

        <hr className="text-defaultBlue_40 mt-[20px] mx-[27px]" />

        <MenuItem
          icon={
            <Cog6ToothIcon
              className={`w-6 h-6 text-secondary_bg text-[#6E6C8E] ${settingsIcon}`}
            />
          }
          className={`mt-[20px] ${settingsActive}`}
          component={<Link to="/settings" />}
        >
          <p className="font-normal text-[#6E6C8E] text-[16px] mt-1">
            Settings
          </p>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default ProSideBar;

import React, { useEffect, useState, useCallback } from "react";
import { Select } from "antd";
import BaseService from "../../../../helpers/baseServices";

interface theProps {
  onChange: (selected: string) => void;
  courseId?: string;
}

interface CoursesProps {
  label: string;
  value: string;
}

const AttendCourseID = ({ onChange, courseId }: theProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [AllCourses, setAllCourses] = useState<CoursesProps[]>([]);
  const [selectedCourses, setSelectedCourses] = useState<string>(
    courseId || ""
  );

  const fetchCourses = useCallback(async () => {
    setIsFetching(true);
    try {
      const { data } = await BaseService.get_api("/robocentre/course");
      if (data?.payload) {
        const arrangedResponse: CoursesProps[] = data.payload.map(
          (item: any) => ({
            label: item.title,
            value: item._id,
          })
        );
        setAllCourses(arrangedResponse);
      }
    } catch (error) {
      console.error("Failed to fetch courses:", error);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  useEffect(() => {
    if (courseId) {
      setSelectedCourses(courseId);
    }
  }, [courseId]);

  const handleChange = useCallback(
    (value: string) => {
      setSelectedCourses(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <div>
      <Select
        id="category"
        value={selectedCourses || undefined}
        className="custom-placeholder h-[48px] w-full"
        options={AllCourses}
        onChange={handleChange}
        disabled={isFetching}
        loading={isFetching}
        placeholder="Select course"
        allowClear
      />
    </div>
  );
};

export default AttendCourseID;

import React, { useEffect, useState, useCallback } from "react";
import { Select } from "antd";
import BaseService from "../../../../helpers/baseServices";

interface theProps {
  onChange: (selected: string) => void;
  academicIds?: string;
  schoolId: string | null;
}

interface CoursesProps {
  label: string;
  value: string;
}

const AttendAcademic = ({ onChange, academicIds, schoolId }: theProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [AllAcamedic, setAllAcamedic] = useState<CoursesProps[]>([]);
  const [selectedAcamedics, setSelectedAcademic] = useState<string>(
    academicIds || ""
  );

  const fetchAcamedics = useCallback(async () => {
    if (!schoolId) return;
    setIsFetching(true);
    try {
      const { data } = await BaseService.get_api(
        `/robocentre/roboschool/onboarding/academic-term/${schoolId}`
      );
      if (data?.payload) {
        const arrangedResponse: CoursesProps[] = data.payload.map(
          (item: any) => ({
            label: item.name,
            value: item._id,
          })
        );
        setAllAcamedic(arrangedResponse);
      }
    } catch (error) {
      console.error("Failed to fetch academic terms:", error);
    } finally {
      setIsFetching(false);
    }
  }, [schoolId]);

  useEffect(() => {
    if (schoolId) {
      fetchAcamedics();
    }
  }, [schoolId, fetchAcamedics]);

  useEffect(() => {
    if (academicIds) {
      setSelectedAcademic(academicIds);
    }
  }, [academicIds]);

  const handleChange = useCallback(
    (value: string) => {
      setSelectedAcademic(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <div>
      <Select
        id="category"
        value={selectedAcamedics || undefined}
        className="custom-placeholder h-[48px] mb-2 w-full"
        options={AllAcamedic}
        onChange={handleChange}
        disabled={isFetching || !schoolId}
        loading={isFetching}
        placeholder="Select Academic"
        allowClear
      />
    </div>
  );
};

export default AttendAcademic;

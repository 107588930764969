import React from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const StudentsData = ({ grade, id }: { grade: any; id: string }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/class/${id}/${grade?.name}`);
  };
  return (
    <div
      onClick={handleClick}
      className="flex cursor-pointer w-full items-center justify-between border-2 px-2 border-[#C2C2F7] rounded-[60px] py-2 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:border-[#9191f7]"
    >
      <p className="w-[25px] mx-4 h-[25px] border-2 border-[#C2C2F7] rounded-full"></p>
      <p className="w-[30%]">{"Fred Alottey"}</p>

      <div className="h-[30px] w-[2px] bg-[#C2C2F7] mx-3"></div>

      <p>{grade?.type}</p>

      <div className="h-[30px] w-[2px] bg-[#C2C2F7] mx-3"></div>

      <p className="mr-4">Grade 4 - Grade 10</p>
    </div>
  );
};

export default StudentsData;
